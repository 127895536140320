import React, { useState } from 'react';
import './UploadSheet.css';
import axios from 'axios';

function UploadSheet() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    
    if (['csv', 'xlsx'].includes(fileType)) {
      setFile(uploadedFile);
      setError(null);
    } else {
      setError('CSV 또는 XLSX 파일만 업로드 가능합니다.');
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setError('파일을 선택해주세요.');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/process-excel', formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // 파일 다운로드
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `processed_${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setFile(null);
    } catch (err) {
      setError(err.response?.data?.error || '파일 처리 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <h1 className="upload-title">큐시트 업로드</h1>
      
      <div className="upload-section">
        <label className="upload-button">
          파일 선택하기
          <input
            type="file"
            accept=".csv,.xlsx"
            onChange={handleFileUpload}
            className="file-input"
          />
        </label>
        
        {file && (
          <>
            <p className="file-info">선택된 파일: {file.name}</p>
            <button 
              onClick={handleSubmit} 
              disabled={loading}
              className="submit-button"
            >
              {loading ? '처리중...' : '파일 처리하기'}
            </button>
          </>
        )}

        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default UploadSheet; 